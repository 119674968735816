/** * 产品服务-万界云 */
<template>
   <div>
     <v-banner
        single-line
        height="400"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerSaasCloud400.jpg"
          height="100%"
          :transition="false"
          >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 150px;">
          <v-card color="transparent banner-text" flat>
            <v-card-title class="pa-0 font-size-50 family-Bold font-size-banner-title">
              万界云
            </v-card-title>
            <v-card-title class="pa-0 font-size-18 mt-7 font-size-banner-tip">
              技术+私域+运营赋能，致力于品牌更深度的服务
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  

    <!-- 万界云产品优势 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <!-- <span>多场景营销，持续提升经营指标</span> -->
          <span>多场景运营，持续提升经营指标</span>
          <!-- <span>根据客户需求定制营销玩法，支持对接各平台积分体系、权益体系</span> -->
          <span>根据客户需求定制趣味玩法，支持对接各平台积分体系、权益体系</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.four" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.img' />
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text class="tip"> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 万界云产品体系 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>FUN MARKETING</p>
        <div>
          <span>万界云产品体系</span>
          <!-- <span>服务活动营销全链路，一体化赋能营销目标</span> -->
          <span>服务活动全链路，一体化赋能运营目标</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in data.second" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.second" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k"><img :src='v.img' /></div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div class="right" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="tip" v-text="v.text" />
                  <!-- <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" /> -->
                </div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 万界云产品优势 -->
    <!-- <v-card class="warps warp1 warp3" :flat="true">
      <div class="public-title">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>万界云产品优势</span>
          <span>基于AR/VR技术，提供定制化营销服务</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.frist" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.img' />
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text class="tip"> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card> -->

    <!-- 精品模板 丰富你的创作灵感 -->
    <v-card class="warps warp4" :flat="true">
      <div class="public-title">
        <p>BOUTIQUE TEMPLATE</p>
        <div>
          <span>万界云应用场景</span>
          <!-- <span>全行业覆盖，打造刷爆朋友圈的趣味互动营销活动</span> -->
          <span>全行业覆盖，打造刷爆朋友圈的趣味互动活动</span>
        </div>
      </div>
      <div class="con">
        <v-row>
          <v-col
            v-for="n in data.five"
            :key="n.length"
            class="d-flex child-flex"
            cols="3"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.img+')'}">
              <div>
                {{ n.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- 万界云产品优势 -->
    <!--<v-card class="warps warp5" :flat="true">-->
    <!--  <div class="public-title">-->
    <!--    <p>COOPERATE</p>-->
    <!--    <div>-->
    <!--      <span>万界云合作客户</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="con">-->
    <!--    <v-img-->
    <!--      src="https://h5.ophyer.cn/official_website/other/saas-hzhb1.jpg"-->
    <!--    />-->
    <!--  </div>-->
    <!--</v-card>-->
   </div>
</template>

<script>
import textData from '@/static/textData.js'

export default {
  name: "SaasCloud",
  data(){
    return{
      data: textData.SaasCloud,
      model: 0,
      tab: null,

    }
  },
  methods: {
   
  }
}
</script>

<style scoped lang='scss'>
.banner {
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  ::v-deep.v-card__title {
    color: #fff !important;
  }
  ::v-deep.v-btn__content {
    color: #2c7aff !important;
  }
  ::v-deep .btn-hover:hover {
    background-color: #e1e2e2 !important;
  }
}

.warps {
  width: 1200px;
  margin: 0 auto;
  
}
.warp1 {
  .con {
    margin-top: 20px;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp3 {
  .con {
    margin-top: 25px;
  }
  img {
    width: 120px !important;
    height: 120px !important;
  }
}
.warp2 {
  .g-tabs {
    margin-top: 15px;
  }
  .box {
    width: 1000px;
    margin: 50px auto 0;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 40px;
    img {
      width: 400px;
      height: 320px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      //font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 400 !important;
      color: #333;
    }
    .tip {
      line-height: 26px;
      margin-top: 20px;
      color: #666;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}

.warp4 {
  margin-bottom: 60px;
  .con {
    margin-top: 35px;
    .box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

// ::v-deep .v-carousel__controls{
//   display: none !important;
// }
.warp5 {
  margin-top: 30px;
  width: 100%;
  background: #f5f9fd;
  padding-top: 25px;
  // margin-bottom: -70px;
  .public-title {
    margin-top: 30px;
  }
  .con {
    width: 1200px;
    min-height: 300px;
    margin: -25px auto 0;
  }
}
</style>